import React from "react";
import PriceSheetPDF from "../../PriceSheetPDF";

function PriceInput({setStep, prices, setPrices, shopDetails, priceError}) {
  const [errors, setErrors] = React.useState({});
  
  const config = [
    {
      'title': 'Verres dynamiques Transitions',
      'rows': [
        {
          'id': 'dyn_p_sports_fp',
          'label': 'UNIFOCAUX SPORTS 1.5 TRANSITIONS AR UV'
        },
        {
          'id': 'dyn_seemax_sports',
          'label': 'PROGRESSIFS SPORTS 1.5 TRANSITIONS AR UV'
        },
        {
          'id': 'dyn_presio_touch',
          'label': 'UNIFOCAUX 1,5 TRANSITIONS AR UV'
        },
        {
          'id': 'dyn_spherical',
          'label': 'PROGRESSIFS 1,5 TRANSITIONS AR UV'
        },
        {
          'id': 'addon_dyn_trans_tractive_pol',
          'label': 'Supplément: Transitions Xtractive Polarized'
        }
      ]
    },
    {
      'title': 'Verres Solaires',
      'rows': [
        {
          'id': 'sol_p_sports',
          'label': 'UNIFOCAUX SPORTS 1.5 Teintes Unis AR UV Solaire'
        },
        {
          'id': 'sol_seemax_sports',
          'label': 'PROGRESSIFS SPORTS 1.5 teintés Unis AR UV Solaire'
        },
        {
          'id': 'sol_presio_touch',
          'label': 'UNIFOCAUX 1,5 Teintés Unis AR UV Solaire'
        },
        {
          'id': 'sol_spherical',
          'label': 'PROGRESSIFS 1,5 Teintés Unis AR UV Solaire'
        },
        {
          'id': 'addon_sol_sun_style',
          'label': 'Supplément: Sun +'
        }
      ]
    },
    {
      'title': 'Verres Blancs',
      'rows': [
        {
          'id': 'bla_p_sports_fp',
          'label': 'UNIFOCAUX SPORTS 1,5 AR UV'
        },
        {
          'id': 'bla_seemax_sports',
          'label': 'PROGRESSIFS SPORTS 1,5 AR UV'
        },
        {
          'id': 'bla_presio_touch',
          'label': 'UNIFOCAUX 1.5 AR UV'
        },
        {
          'id': 'bla_soltes_wide',
          'label': 'MI-DISTANCE 1,5 AR UV:'
        },
        {
          'id': 'bla_spherical',
          'label': 'PROGRESSIFS 1.5 AR UV'
        },
        {
          'id': 'addon_bla_pure_blue_uv',
          'label': 'Supplément: Pure Blue UV'
        }
      ]
    },
    {
      'title': 'Supplément VERRES AMINCIS 1,6 Verres',
      'rows': [
        {
          'id': 'extra_addon_amincis_1.6_progressif',
          'label': 'Progressifs'
        },
        {
          'id': 'extra_addon_amincis_1.6_unifocaux',
          'label': 'Unifocaux + Mi-distance'
        }
      ]
    }
  ]
  return (
    <div className="price-wrapper">
      <div className="price-input-wrapper">
        <h1>Renseignez vos tarifs par paire</h1>
        <div className="price-input-block">
          {config.map((bloc, i) => (
            <>
              <p>{bloc.title}</p>
              <div key={i}>
                {bloc.rows.map((item, index) => (
                  <>
                  <div key={index} className={item.id.includes('addon') ? 'addon' : '' }>
                    <label htmlFor={item.id}>{item.label}</label>
                    <div>
                      <input type="number" id={item.id} value={prices[item.id].price ?? null} onChange={() => {
                        const rawValue = document.getElementById(item.id).value;
                        if(isNaN(rawValue)) {
                          return;
                        }
                        const value = Math.max(0, parseInt(rawValue));
                        if(value < 10 || value > 999){
                          setErrors(prevState => ({
                            ...prevState,
                            [item.id]: 'La valeur doit être comprise entre 10 et 999€ inclus'
                          }));
                        } else {
                          setErrors(prevState => ({
                            ...prevState,
                            [item.id]: null
                          }));
                        }
                        setPrices(prevState => ({
                          ...prevState,
                          [item.id]: {...prevState[item.id], price: value}
                        }));
                      }} />
                      &euro;
                    </div>
                  </div>
                  {errors[item.id] && <div className="error">{errors[item.id]}</div>}
                  {priceError && prices[item.id].price === 0 && <div className="error">La saisie de ce champ est obligatoire.</div>}
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="price-input-preview margin-top">
        <a href="/assets/pdf/tapis_vente_personnalisable_SWITCH_2024_prix_v1.1.pdf" target="_blank"><img src="/assets/images/info.svg" alt=""/>Accéder à la sélection de produits Nikon avec nos prix de vente conseillés</a>
        <PriceSheetPDF prices={prices} shopDetails={shopDetails}/>
      </div>
    </div>
  );
}

export default PriceInput;
