import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import PriceSheetPDF from '../../PriceSheetPDF';

function Download({setStep, prices, shopDetails}) {

  const generatePdf = async () => {
    const existingPdfBytes = await fetch('/assets/pdf/tapis-base.pdf').then(res => res.arrayBuffer());
    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    // Get the first page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()

    // Loop over prices and add them to the PDF
    Object.keys(prices).forEach(key => {
      writeText({font: helveticaFont, key, width, height, firstPage, price: prices[key]});
    });

    // Add custom block for addon_sol_sun_style
    writeText({font: helveticaFont, key: 'addon_sol_sun_style', width, height, firstPage, price: {
      price: prices['addon_sol_sun_style'].price,
      top: 82.5,
      right: 35,
      isAddon: true
    }});

    if(shopDetails?.logo?.buffer) {
      // convert base64 shopDetails.logo.buffer. to Uint8Array
      const base64 = shopDetails.logo.buffer.replace(/^data:image\/\w+;base64,/, "");
      const buffer = new Uint8Array(atob(base64).split("").map(c => c.charCodeAt(0)));
      const logoImage = shopDetails.logo.buffer.includes('jpeg') ? await pdfDoc.embedJpg(buffer) : await pdfDoc.embedPng(buffer);
      const imageHeight = shopDetails.logo.height;
      const logoDims = logoImage.scale(30 / imageHeight);
      firstPage.drawImage(logoImage, {
        x: width - 10 - logoDims.width,
        y: height - ((95 / 100) * height) - 20,
        width: logoDims.width,
        height: logoDims.height,
      });
    }

    if(shopDetails?.name) {
      let boxY = height - ((95 / 100) * height) - 14;
      firstPage.drawText(shopDetails.name.toUpperCase(), {
        x: 10,
        y: boxY,
        size: 14,
        font: helveticaBoldFont,
        color: rgb(0,0,0),
      });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, "tapis_vente_secondes_paires_Nikon.pdf", "application/pdf");
  }

  const writeText = ({font, key, width, height, firstPage, price}) => {
    const boxWidth = 50;
    let fontSize = 18;
    let boxHeight = fontSize;
    if (price.price) {
      const {top, right, left, isAddon, isExtraAddon} = price;
      if(isAddon) {
        fontSize = 11;
        boxHeight = fontSize;
      } else if (isExtraAddon) {
        fontSize = 7;
        boxHeight = fontSize;
      }
      const text = `${isAddon || isExtraAddon ? '+ ' : ''}${price.price} €`;
      const textWidth = font.widthOfTextAtSize(text, fontSize);
      let boxX = width - ((right / 100) * width) - (boxWidth * 1.2);
      if(left){
        boxX = ((left / 100) * width) - 25;
      }
      
      let boxY = height - ((top / 100) * height) - (boxHeight * 1);

      if(isExtraAddon) {
        boxX += 5;
        boxY -= 1;
      }

      firstPage.drawText(text, {
        x: boxX + boxWidth - textWidth,
        y: boxY,
        size: fontSize,
        font: font,
        color: rgb(0,0,0),
      })
    }
  }

  const download = (content, filename, contentType) => {
    const a = document.createElement("a");
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  }

  return (
    <div className="price-wrapper">
      <div className="price-input-wrapper">
        <h1>Votre tapis de vente est prêt !</h1>
        <button className='btn' onClick={() => generatePdf()}><img src="/assets/images/ico-pdf.png" alt="pdf" width="20" /> Exporter en pdf</button>
        <br />
        <button className='btn' onClick={() => window.open("/assets/pdf/tapis_vente_personnalisable_SWITCH_2024_verso_v1.1.pdf")}><img src="/assets/images/ico-pdf.png" alt="pdf" width="20" /> Verso informations complémentaires</button>
        <br />
        <button className='btn' onClick={() => setStep(4)}><img src="/assets/images/chevron-left-circle.svg" width="20" /> Revenir au début</button>

      </div>
      <div className="price-input-preview">
        <PriceSheetPDF prices={prices} shopDetails={shopDetails} />
      </div>
    </div>
  );
}

export default Download;
