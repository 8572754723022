function PriceSheetPDF({prices, shopDetails}) {

  const loop = (prices) => {
    return Object.keys(prices).map((key) => {
      const priceObj = prices[key];
      const { top, right, left, isAddon, isExtraAddon } = priceObj;
      let price = priceObj.price;
      const addonClass = isAddon ? 'addon' : '';
      const extraAddonClass = isExtraAddon ? 'extra-addon' : '';
      if(isNaN(price)) {
        price = 0;
      }
      const style = {
        'top': `${top}%`
      }
      if(right){
        style.right = `${right}%`
      }
      if(left){
        style.left = `${left}%`
      }
      return (
        <div className={`price ${addonClass} ${extraAddonClass}`} style={style}>{isAddon || isExtraAddon ? '+ ' : ''}{price ?? ''} &euro;</div>
      )}
   ); 
  } 

  return (
    <div className="price-sheet-pdf-wrapper">
      {loop(prices)}

      <div className="price addon" style={{'right': '35%', 'top': '82%'}}>+ {prices?.['addon_sol_sun_style']?.price ?? ''} &euro;</div>
      {shopDetails && (
        <div className="shop-details">
          <div className="shop-name">{shopDetails.name}</div>
          <div className="shop-logo">
            <img alt="" src={shopDetails?.logo?.buffer} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PriceSheetPDF;
