import { useEffect, useState } from "react";
import Customization from "./steps/customization";
import Download from "./steps/download";
import PriceInput from "./steps/priceInput";
import Splash from "./steps/splash";

export const priceTpl = {
  "dyn_p_sports_fp": {price: 149, top: 36, right: 68},
  "dyn_seemax_sports": {price: 179, top: 41.5, right: 68},
  "dyn_presio_touch": {price: 129, top: 71, right: 68},
  "dyn_spherical": {price: 159, top: 76.5, right: 68},
  "addon_dyn_trans_tractive_pol": {price: 30, top: 47.5, right: 68, isAddon: true},
  "sol_p_sports": {price: 89, top: 36, right: 35},
  "sol_seemax_sports": {price: 119, top: 41, right: 35},
  "sol_presio_touch": {price: 69, top: 70.5, right: 35},
  "sol_spherical": {price: 99, top: 76, right: 35},
  "addon_sol_sun_style": {price: 30, top: 47.5, right: 35 , isAddon: true},
  "bla_p_sports_fp": {price: 89, top: 36, right: 2},
  "bla_seemax_sports": {price: 119, top: 41.5, right: 2},
  "bla_presio_touch": {price: 69, top: 65, right: 2},
  "bla_soltes_wide": {price: 89, top: 70.5, right: 2},
  "bla_spherical": {price: 99, top: 76, right: 2},
  "addon_bla_pure_blue_uv": {price: 10, top: 82, right: 2, isAddon: true},
  "extra_addon_amincis_1.6_progressif": {price: 30, top: 84, left: 7, isExtraAddon: true},
  "extra_addon_amincis_1.6_unifocaux": {price: 20, top: 85.7, left: 13.7, isExtraAddon: true},
}

function Wrapper() {

  const [step, setStep] = useState(0);
  const [shopDetails, setShopDetails] = useState(null);
  const [prices, setPrices] = useState(priceTpl);
  const [priceError, setPriceError] = useState(false);

  useEffect(() => {
    if(step === 2){
      // check that all prices are above 0
      let isValid = true;
      Object.keys(prices).forEach((key) => {
        if(prices[key].price <= 0){
          isValid = false;
        }
      });
      if(!isValid){
        setStep(1);
        setPriceError(true);
      } else {
        setPriceError(false);
      }
    }
    if(step > 3) {
      window.location.reload();
    }
  }, [step])

  return (
    <div className={`main-layout step-${step}`}>
      <div className="page-wrapper">
        {step === 0 && (
          <Splash setStep={setStep} />
        )}
        {step === 1 && (
          <PriceInput setStep={setStep} prices={prices} setPrices={setPrices} shopDetails={shopDetails} priceError={priceError}/>
        )}
        {step === 2 && (
          <Customization setStep={setStep} prices={prices} setShopDetails={setShopDetails} shopDetails={shopDetails}/>
        )}
        {step === 3 && (
          <Download setStep={setStep} prices={prices} shopDetails={shopDetails}/>
        )}
      </div>
      {step !== 0 && (
        <nav>
          <button className="btn" onClick={() => setStep(step - 1)}><img src="/assets/images/chevron-left-circle.svg" width="20" /> Étape précédente</button>
          <button className="btn" onClick={() => setStep(step + 1)}>Étape suivante <img src="/assets/images/chevron-right-circle.svg" width="20" /> </button>
        </nav>
      )}
    </div>
  );
}

export default Wrapper;
